body {
  padding: 0;
  margin: 0;
}
@import url('https://fonts.googleapis.com/css2?family=Merienda+One&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Marck+Script&display=swap');
.parallax-item {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
margin-top: 20px;
  width: 100%;
  min-height: 74vh;
  flex-wrap: wrap;
}

/* .parallax-item h2 {
  font-size: 3rem;
  text-transform: uppercase;
  background-color: whitesmoke;
  padding: 1rem;
  border-radius: 1rem;
} */

.parallax-item:first-child {
  background: url('../img2/tim-mossholder-YC6RVdoTtIk-unsplash.jpg');
  background-size: cover;
  background-attachment: fixed;
  /* height: 50vh; */
}

.parallax-item:nth-child(2) {
  background: white;
  background-attachment: fixed;
}
/* .parallax-item:nth-child(3) {
  background: #fdd835;
  background-attachment: fixed;
} */

.parallax-item:nth-child(6) {
  background: url("../img/background3.jpg");
  background-size: cover;
  background-position: center;
  background-attachment: fixed; 
  
}

.parallax-item:nth-child(7) {
  /* background: #1565c0; */
}

@media screen and (max-width: 768px) {
  .parallax-item h2 {
    /* font-size: 3rem; */
  }
  .roww{
    flex-wrap: wrap;
    margin-top: 30px
  }
  .roww2{
    flex-wrap: wrap;
    margin-top: 30px
  }
  .flex1{
    width:471px ;
  }
  .flex2{
    width:471px;
  }
  .flex3{
    width:471px;
  }
  .flex4{
    width:471px ;
  }
}

@media screen and (max-width: 600px) {

  .flex1{
    width:471px ;
  }
  .flex2{
    width:471px;
  }
  .flex3{
    width:471px;
  }
  .flex4{
    width:471px ;
  }
}

.text1{

  text-transform: capitalize;
  font-family: 'Marck Script', cursive;
  font-size: 50px;
  color: #C7A17A;
}
.flex1{
  border-bottom-right-radius: 188px;
    border-top-left-radius: 61px;

}
.flex2{
  border-bottom-right-radius: 188px;
    border-top-left-radius: 61px;
    background-attachment: fixed;
   
}
.flex3{
  border-bottom-right-radius: 188px;
    border-top-left-radius: 61px;

}
.flex4{
  border-bottom-right-radius: 188px;
    border-top-left-radius: 61px;
    background-attachment: fixed;
   
}

@media screen and (max-width: 1120px) {
  .roww {
    flex-direction: column;
    gap: 15rem !important;
} 
  .roww2{
    flex-direction: column;
    margin: 68px;
  }
  
}

@media screen and (max-width: 500px) {

  .flex1{
    width:332px !important;
  }
  .flex2{
    width:332px !important;

  }
  .flex3{
    width:332px !important;

  }
  .flex4{
    width:332px !important;

  }
}
.roww{
  gap: 28rem;
}
.text2{
  text-align: center;
}

@media only screen and (max-width: 900px) {

.cards{
  flex-direction: column;
  gap: 2rem;
  margin-top: 10px;

}
.cards2{
  flex-direction: column;
  gap: 2rem;

  align-items: center;
  margin-top: 12px;

}
.fll{
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;

}

}
.para55{
  width: 34%;      
}
.h11{
  text-align: center;
  letter-spacing: 5px;
}
.p11{
  text-align: center;
  font-size: 18px;
}
.blue{
  margin-top: 50px;
  background-color: #754C29!important;
}
.fll{
  padding-top: 65px;
    color: white;
    display: flex;
    justify-content: space-evenly;
}
.st{
  width: 28%;
}
.st>img{
  width: 100%;
}
@media only screen and (max-width: 600px) {

  .st{
    width: 50% ;
  }
  
}


@media only screen and (max-width: 800px) {

  .st{
    width: 60% !important;
  }
  
}
@media screen and (max-width: 900px) {


  .st>img{

  }
  .st {
    width: 43%;
}
.parallax-item:first-child {
  background: url('../img2/tim-mossholder-YC6RVdoTtIk-unsplash.jpg');
  background-size: cover;
  background-attachment: unset;
  height: 50vh;
}
.anti{
  margin-top: 33px !important;
}
.parallax-item {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  margin-top: 20px;
  width: 100%;
  min-height: 0vh !important;
  flex-wrap: wrap;
}

}   
.anti{
  margin-top: 250px ;
}